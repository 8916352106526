<template>
  <div id="login">
    <div class="login-container">
      <el-form
              :model="ruleForm2"
              :rules="rules2"
              status-icon
              ref="ruleForm2"
              label-position="left"
              label-width="0px"
              class="demo-ruleForm login-page"
      >
        <h1 class="title">GM后台管理系统</h1>
        <el-form-item prop="username">
          <el-input type="text" v-model="ruleForm2.username" auto-complete="off" placeholder="用户名"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
                  type="password"
                  v-model="ruleForm2.password"
                  auto-complete="off"
                  placeholder="密码"
          ></el-input>
        </el-form-item>
        <div class="form-group" style="display: flex;align-items: center;">
          <el-form-item prop="code">
            <el-input
                    type="text"
                    id="code"
                    v-model="ruleForm2.code"
                    class="code"
                    auto-complete="off"
                    placeholder="验证码"
                    @keyup.enter.native="Submit"
            ></el-input>
          </el-form-item>
          <div class="login-code" @click="refreshCode">
            <!--验证码组件-->
            <s-identify :identifyCode="identifyCode"></s-identify>
          </div>
        </div>
        <el-form-item style="width:100%;">
          <el-button
                  type="primary"
                  style="width:100%; "
                  @click="submitForm('ruleForm2')"
                  :loading="logining"
          >登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import SIdentify from "../../components/Login/sidentify";
  import { Encrypt, Decrypt } from "../../assets/js/utils";
  export default {
    components: { SIdentify },
    data() {
      return {
        identifyCodes: "1234567890abcdefghijklmnopqrstuvwxyz",
        identifyCode: "",
        code: "", //text框输入的验证码
        logining: false,
        ruleForm2: {
          username: "",
          password: "",
          code: ""
        },
        rules2: {
          username: [
            {
              required: true,
              message: "用户名不能为空",
              trigger: "blur"
            }
          ],
          password: [
            {
              required: true,
              message: "密码不能为空",
              trigger: "blur"
            }
          ],
          code: [
            {
              required: true,
              message: "验证码不能为空",
              trigger: "blur"
            }
          ]
        },
        Authorization: ""
      };
    },
    mounted() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    methods: {
      // 获取用户信息
      getUserInfo() {
        var params = this.Authorization;
        let that = this;
        this.$Http({
          url: "/api/v1/users/info",
          params,
          successful(res, data) {
            localStorage.setItem("UserInfoID", data.data.id);
          }
        });
      },
      Submit(event) {
        if (event.keyCode == 13) {
          this.submitForm();
        }
      },
      //验证码
      randomNum(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      },

      refreshCode() {
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
      },
      makeCode(o, l) {
        for (let i = 0; i < l; i++) {
          this.identifyCode += this.identifyCodes[
                  this.randomNum(0, this.identifyCodes.length)
                  ];
        }
      },
      submitForm(ruleForm2) {
        var params = this.ruleForm2;
        var dat = this.ruleForm2.code.toUpperCase();
        let that = this;
        if(dat.length < 4){
          return false;
        }
        this.$refs.ruleForm2.validate(valid => {
          if (valid && dat === this.identifyCode.toUpperCase()) {
            this.$Http({
              url: "/api/v1/login",
              params,
              successful(res, data) {
                if (!data.status) {
                  that.$message.error(data.message);
                } else {
                  localStorage.setItem("token", data.data.token);
                  that.Authorization = data.data.token
                  that.getUserInfo();
                  that.$router.push("/welcome");
                  that.$message.success("登录成功");
                  localStorage.setItem("ms_username", params.username);
                }
              }
            });
          } else {
            that.$message.error("登录失败！");
            that.refreshCode();
          }
        });
      }
    }
  };
</script>

<style lang="less" scoped>
  #login {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    z-index: -10;
    zoom: 1;
    background-color: #fff;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    .login-container {
      .title {
        text-align: center;
      }
      .el-form-item {
        margin: 22px 0;
      }
      .form-group {
        .el-form-item {
          margin: 0;
        }
      }
      .login-code {
        cursor: pointer;
        height: 40px;
        .s-canvas {
          height: 40px;
        }
      }
    }
  }
</style>